<template>
  <v-dialog
    v-model="dialogCancelarOs"
    @click:outside="$emit('update:dialogCancelarOs', false)"
    @keydown.esc="$emit('update:dialogCancelarOs', false)"
    width="500"
  >
    <v-card>
      <v-card-title>
        <v-icon color="red" left>mdi-alert</v-icon>
        {{ $tc("global.cancelar").toUpperCase() + ' ' + $tc("global.ordensServicos").toUpperCase() }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="mt-2 subtitle-1">
          Deseja realmente cancelar a ordem de serviço?
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="$emit('update:dialogCancelarOs', false)">
          {{ $tc("global.voltar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :loading="loading"
          @click="openCancelarOs"
          small
          color="red"
          class="white--text"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $tc("global.cancelar") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelarOrdemServicoAntesDaBaixa, cancelarOrdemServicoFaturadaCaixaAfItem, cancelarOrdemServicoFaturada } from "@/api/ordem-servico/ordem_servicos";

export default {
  name: "DialogEnviarCaixaOrdemServicoManual",

  props: {
    dialogCancelarOs: {
      type: Boolean,
      default: false,
    },
    registroCaixa: {
      type: Object,
    },
    ordemServico: {
      type: Object,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  methods: {
    closeDialog() {
      this.$emit("close");
    },
    async openCancelarOs(){
      this.loading = true;
      if (this.ordemServico) {
        if (this.ordemServico.data_pago_caixa) {
          await cancelarOrdemServicoFaturada(this.ordemServico.id)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("cancelar-os");
              this.loading = false;
              this.$emit("update:dialogCancelarOs", false);
            }
          });
        } else {
          await cancelarOrdemServicoAntesDaBaixa(this.ordemServico.id)
          .then((response) => {
            if (response.status === 200) {
              this.$emit("cancelar-os");
              this.loading = false;
              this.$emit("update:dialogCancelarOs", false);
            }
          })
          .catch(() => {
            this.loading = false;
          });
        }
      }
      else {
        await cancelarOrdemServicoFaturadaCaixaAfItem(this.registroCaixa.id)
        .then((response) => {
          if (response.status === 200) {
            this.$emit("cancelar-os");
            this.loading = false;
            this.$emit("update:dialogCancelarOs", false);
          }
        })
        .catch(() => {
          this.loading = false;
        });
      }
      this.closeDialog();
    }
  },
};
</script>

<style></style>
